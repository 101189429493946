import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { BehaviorSubject, Observable } from 'rxjs';

// LocalStorage Keys
const PREVIOUS_URL_KEY = 'previousUrl';
const TOKEN_KEY = 'token';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  accessToken = '';

  private isAuthenticated$ = new BehaviorSubject(false);

  constructor(
    private router: Router,
    private location: Location,
    private window: Window,
  ) {
    this.getToken();
  }

  public isAuthenticatedAsync(): Observable<boolean> {
    return this.isAuthenticated$.asObservable();
  }

  public isAuthenticated(): boolean {
    return !!this.getToken();
  }

  public setToken(token: string) {
    this.window.localStorage.setItem(TOKEN_KEY, token);
    this.accessToken = token;
    this.isAuthenticated$.next(true);
  }

  public logout() {
    this.clearToken();
    this.router.navigateByUrl('/');
  }

  public loginFromInvalidToken() {
    this.accessToken = '';
    this.window.localStorage.removeItem(TOKEN_KEY);
    this.window.localStorage.setItem(PREVIOUS_URL_KEY, this.location.path(true));
    this.isAuthenticated$.next(false);
    this.router.navigate(['session-expired']);
  }

  getPreviousUrl(): string {
    return this.window.localStorage.getItem(PREVIOUS_URL_KEY);
  }

  setPreviousUrl(path) {
    this.window.localStorage.setItem(PREVIOUS_URL_KEY, path);
  }

  removePreviousUrl() {
    this.window.localStorage.removeItem(PREVIOUS_URL_KEY);
  }

  getToken(): string {
    if (!this.accessToken) {
      const token = this.window.localStorage.getItem(TOKEN_KEY) || '';
      this.accessToken = token;
    }

    return this.accessToken;
  }

  getDecodedToken(): any {
    const token = this.getToken();
    if (token) {
      // https://dev.azure.com/symetra/SwiftTerm/_workitems/edit/467425
      // we need to try/catch this or else tests randomly fail
      // other if checks seemed ineffective
      try {
        return jwt_decode(token) ;
      } catch (exception) {
        return {};
      }
    } else {
      return {};
    }
  }

  isTokenExpired(): boolean {
    const token = this.getToken();
    if (!token) {
      return true;
    }
    const decodedToken = this.getDecodedToken();
    if (!decodedToken.exp) {
      return true;
    }
    const expirationDate = new Date(0);
    expirationDate.setUTCSeconds(decodedToken.exp);
    return expirationDate.valueOf() <= new Date().valueOf()
  }

  clearToken() {
    this.window.localStorage.removeItem(TOKEN_KEY);
    this.accessToken = '';
    this.isAuthenticated$.next(false);
  }
}
