<div
  class="banner-wrapper"
  [class]="config?.type"
>
  <span class="icon">
    <img
      *ngIf="config?.type === BannerNotificationType.WARNING"
      src="assets/warning-triangle.svg"
    />
    <img
      *ngIf="config?.type === BannerNotificationType.ERROR"
      src="assets/error-triangle.svg"
    />
    <img
      *ngIf="config?.customIcon"
      [src]="config?.customIcon"
    />
  </span>
  <span class="label">{{config?.label}}</span>
  <span class="description">{{config?.description}}</span>
  <a
    *ngIf="config?.linkText && config?.internalLink"
    class="link"
    [routerLink]="config?.internalLink"
  >{{config?.linkText}}</a>
  <a
    *ngIf="config?.linkText && config?.externalLink"
    class="link"
    [href]="config?.externalLink"
    target="_blank"
  >{{config?.linkText}}</a>

  <span class="description">{{config?.textAfterLink}}</span>
  <!--
    Not sure what a close would actually look like.
    But the Figma mentioned being able to hide some banners
  -->
  <button *ngIf="config?.closable" (click)="close()">close</button>
</div>
