import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { BenefitsService } from '@services/benefits.service';
import { OnboardingFormStateService } from '@services/form-state/onboarding-form.state.service';
import { IS_ENTITY_OWNED, ONBOARDING_CODE_KEY } from '@utils/constants';
import { LOCAL_STORAGE_TOKEN } from 'src/app/utils/tokens';
import { environment } from 'src/environments/environment';

import { PolicyPreview } from './types';

// This API Service does not extend the base ApiSevice.
@Injectable({
  providedIn: 'root',
})
export class GettingStartedService {
  private associatedEmail: string;
  private _isPolicyEntityOwned: boolean;

  constructor(
    protected http: HttpClient,
    @Inject(LOCAL_STORAGE_TOKEN) private storage: Storage,
    private benefitsService: BenefitsService,
    private onboardingFormStateService: OnboardingFormStateService
  ) {}

  getAssociatedEmail() {
    return this.associatedEmail;
  }
  
  isPolicyEntityOwned(): boolean {
    return this._isPolicyEntityOwned ?? this.storage.getItem(IS_ENTITY_OWNED) === 'true';
  }

  exchangeCode(code): Observable<PolicyPreview> {
    return this.http
      .post<PolicyPreview>(`${environment.baseApiUrl}/verify`, {
        code,
      })
      .pipe(
        map((result: any) => {
          const appDetails = result.appDetails;
          this._isPolicyEntityOwned = appDetails.primarySigner != null ? true : false;
          // Save the value to local storage so it can be accessed when page reloads
          this.storage.setItem(IS_ENTITY_OWNED, this._isPolicyEntityOwned.toString());
          this.associatedEmail = appDetails.owner.email ?? appDetails.primarySigner.email;
          appDetails.expiration = appDetails.expiration * 1000;
          appDetails.benefits = appDetails.benefits.map((benefit) => {
            return this.benefitsService.getBenefitDetails(benefit);
          });

          this.onboardingFormStateService.setPolicyPreview(appDetails);

          return appDetails;
        })
      );
  }

  saveCode(code) {
    this.storage.setItem(ONBOARDING_CODE_KEY, code);
    return of({});
  }

  getCode() {
    return this.storage.getItem(ONBOARDING_CODE_KEY);
  }

  // path will need to be verified and maybe changed
  declinePlan(code) {
    return this.http.post(`${environment.baseApiUrl}/decline-plan`, {
      code,
    });
  }

  saveSurveyFeedbackToDB(policyId: string, surveyFeedback, code: string) {
    const postBody = {
      status: 'declined',
      surveyResponse: surveyFeedback,
      code,
    };
    return this.http.post(
      `${environment.baseApiUrl}/onboarding/${policyId}/survey`,
      postBody
    );
  }

  requestNewEmailLink(code: string) {
    return this.http.post(
      `${environment.baseApiUrl}/onboarding/request-new-link`,
      {
        code,
      }
    );
  }

  verifyIdentity(ssnDigits: string, code?: string) {
    let onboardingCode = code;
    if (!onboardingCode) {
      onboardingCode = this.getCode();
    }

    if (!onboardingCode) {
      throw new Error('No onboarding code on verify-identity page.');
    }

    return this.http.post(
      `${environment.baseApiUrl}/onboarding/verify-identity`,
      {
        code: onboardingCode,
        ssnDigits,
      }
    );
  }

  register(email: string, password: string, code?: string) {
    if (!code) {
      code = this.getCode();
    }

    return this.http.post(`${environment.baseApiUrl}/onboarding/account`, {
      code,
      email,
      password,
    });
  }

  login(code: string, email: string, password: string) {
    return this.http.post(`${environment.baseApiUrl}/onboarding/login`, {
      code,
      email,
      password,
    });
  }
}
