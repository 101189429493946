import { Component, Input } from '@angular/core';

import { BannerNotification, BannerNotificationType, NotificationsService } from '@services/notifications.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent {

  @Input()
  config: BannerNotification;

  BannerNotificationType = BannerNotificationType;

  constructor(private notificationsService: NotificationsService) { }

  close() {
    this.notificationsService.setBanner(null);
  }

}
