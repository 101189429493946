import 'amazon-connect-chatjs';

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { of, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

import { LOCAL_STORAGE_TOKEN } from '../utils/tokens';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  connect;
  session;

  isConnected = false;

  messageReceived$;
  agentTyping$;
  chatEnded$;

  private transcript$ = new ReplaySubject(15);

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    @Inject(LOCAL_STORAGE_TOKEN) private storage: Storage,
  ) {
    this.connect = (window as any)?.connect;
  }

  sendMessage(message) {
    return this.session.controller.sendMessage({
      message,
      contentType: 'text/plain'
    });
  }

  getTranscript() {
    return this.transcript$.asObservable();
  }

  initiateChat() {
    this.connect.ChatSession.setGlobalConfig({
      region: environment.amazonConnectRegion
    });
    if (this.storage.previousSession) {
      return of(JSON.parse(this.storage.previousSession));
    } else {
      const initiateChatRequest = {
        ParticipantDetails: {
          // Use proper token name
          DisplayName: this.auth.getDecodedToken().sub
        },
        ContactFlowId: environment.amazonConnectContactFlowId,
        InstanceId: environment.amazonConnectInstanceId,
      };
      return this.http.post(environment.amazonConnectUrl, initiateChatRequest).pipe(
        map(this.handleInitiateChatPostRequest)
      );
    }
  }

  handleInitiateChatPostRequest(result: any) {
    const startChatResult = result.data.startChatResult;
    this.storage.previousSession = JSON.stringify(startChatResult);
    return startChatResult;
  }

  connectToChat() {
    return this.initiateChat()
      .pipe(
        switchMap(this.handleInitiateChatResponse),
        switchMap(this.fetchTranscript),
        map(this.handleTranscriptResponse)
      );
  }

  handleInitiateChatResponse(startChatResult: any) {
    this.session = this.connect.ChatSession.create({
      chatDetails: startChatResult,
      type: 'CUSTOMER'
    });

    // not handling failure yet
    const foo = this.session.connect({ metadata: true }).then(result => {
      return result;
    }).catch((error) => {
      this.storage.removeItem('previousSession');
      throw error;
    });

    return foo;
  }

  fetchTranscript() {
    return this.session.getTranscript({ metadata: true });
  }

  handleTranscriptResponse(result: any) {
    // parse previous transcript into our observable
    if (result) {
      result.data.Transcript.map(message => {
        this.transcript$.next(message);
      });
    }

    // handle new messages
    this.session.onMessage((message) => {
      this.transcript$.next(message.data);
    });

    // bindcallback wasnt working as expected but these events should be handled
    // this.agentTyping$ = bindCallback(this.session.onTyping)();
    // this.chatEnded$ = bindCallback(this.session.onEnded)().pipe(
    //   map(() => {
    //     this.storage.removeItem('previousSession');
    //   })
    // );

    // unimplemented
    // this.session.onEnded((event) => {
    //   console.log('ended', { event });
    // });
  }

}
