import { Component, ElementRef, OnDestroy, ViewChild, } from '@angular/core';

import { ChatService } from 'src/app/services/chat.service';

export enum MessageClass {
  Agent = 'agent-message',
  Event = 'event-message',
  System = 'system-message',
  User = 'user-message',
}

@Component({
  selector: 'app-chat-bot',
  templateUrl: './chat-bot.component.html',
  styleUrls: ['./chat-bot.component.scss']
})
export class ChatBotComponent implements OnDestroy {

  isOpen = false;
  isLoading = true;
  shouldPromptNewChat = true;

  currentMessage = '';
  messages = [];
  messageSubscription;
  chatEndedSubscription;

  @ViewChild('chatMessagesList')
  chatMessagesListElement: ElementRef;

  constructor(private chatService: ChatService) { }

  ngOnDestroy(): void {
    this.messageSubscription?.unsubscribe();
    this.chatEndedSubscription?.unsubscribe();
  }

  getMessageClass(message) {
    let messageClass = MessageClass.Agent;
    if (message.Type === 'EVENT') {
      messageClass = MessageClass.Event;
    } else if (message.DisplayName === 'SYSTEM_MESSAGE') {
      messageClass = MessageClass.System;
    } else if (message.ParticipantRole === 'CUSTOMER') {
      messageClass = MessageClass.User;
    }
    return messageClass;
  }

  toggleChat(): void {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.messages = [];
      this.initiateChat();
    }
  }

  sendChat(_event): void {
    if (this.currentMessage.trim() !== '') {
      this.chatService.sendMessage(this.currentMessage);
      this.currentMessage = '';
    }
  }

  addEventContent(message) {
    const newMessage = { ...message };
    if (newMessage.ContentType.includes('participant.joined')) {
      newMessage.Content = `${newMessage.DisplayName} has joined the chat.`;
    } else if (newMessage.ContentType.includes('participant.left')) {
      newMessage.Content = `${newMessage.DisplayName} has left the chat.`;
    } else if (newMessage.ContentType.includes('chat.ended')) {
      newMessage.Content = 'The chat has ended.';
    }

    return newMessage;
  }

  initiateChat() {
    this.shouldPromptNewChat = false;

    this.chatService.connectToChat().subscribe((_result: any) => {
      this.isLoading = false;
      this.subscribeToChatEvents();
    });
  }

  subscribeToChatEvents() {
    if (!this.messageSubscription) {
      this.messageSubscription = this.chatService.getTranscript().subscribe(this.handleGetTranscript);
    }
  }

  handleGetTranscript(message: any) {
    const modifiedMessage = this.addEventContent(message);
    this.messages.push(modifiedMessage);

    // timeout of zero to update the scroll position after the last item is added.
    // (needed for the intial load if there is previous history)
    setTimeout(() => {
      this.chatMessagesListElement.nativeElement.scrollTop = this.chatMessagesListElement.nativeElement.scrollHeight;
    }, 0);
  }
}

// TODO

// Unhandled Events

//   this.session.onTyping((typingEvent) => {
//     if (typingEvent.data.ParticipantRole === 'AGENT') {
//       console.log('Agent is typing')
//     }
//   })

//   this.session.onConnectionBroken((data) => {
//     console.log('Connection broken')
//   })

//   this.session.onEnded(() => {
//     console.log('Chat has ended')
//     localStorage.removeItem('previousSession')
//     this.promptNewChat = true
//   })

// Unhandled Component Methods

// endChat() {
//   this.activeStep.controller.disconnectParticipant()
// }
// sendTypingEvent() {
//   this.session.controller.sendEvent({
//     contentType: 'application/vnd.amazonaws.connect.event.typing'
//   })
// }
// getUsername() {
//   if (auth.auth.signInUserSession.getAccessToken().jwtToken !== '') {
//     return auth.auth.signInUserSession.getAccessToken().getUsername()
//   }
//   return 'Guest'
// }
