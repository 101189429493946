<div
  class="chat"
  [ngClass]="{'open': isOpen}"
>
  <button
    mat-fab
    class="toggle-button"
    color="primary"
    aria-label="Toggle Chat"
    (click)="toggleChat()"
  >
    <img
      *ngIf="!isOpen"
      src="assets/chat-icon.svg"
    />
    <img
      *ngIf="isOpen"
      src="assets/chevron-down.svg"
    />
  </button>
  <div
    class="chat-messages"
    #chatMessagesList
  >
    <div
      class="chat-load"
      *ngIf="isLoading"
    >
      ...Loading chat...
    </div>
    <div
      class="message-container"
      *ngFor="let message of messages"
    >
      <div
        class="chat-name"
        *ngIf="
          message.Type === 'MESSAGE' &&
          message.ParticipantRole !== 'CUSTOMER' &&
          message.DisplayName !== 'SYSTEM_MESSAGE'
        "
      >
        {{ message.DisplayName }}
      </div>
      <div [class]="getMessageClass(message)">
        {{ message.Content }}
      </div>
    </div>
    <button
      class="new-chat"
      hidden
    >
      Start new chat
    </button>
  </div>
  <form
    class="chat-input"
    fxFlexLayout="row"
    fxFlexAlign="center center"
    (submit)="sendChat($event)"
  >
    <input
      type="text"
      [(ngModel)]="currentMessage"
      [ngModelOptions]="{standalone: true}"
    />
    <sya-button
      buttonSize="small"
      buttonStyle="basic"
      class="send-message-button"
      type="submit"
    >Send</sya-button>
  </form>
</div>
