import { Component, ElementRef, HostBinding, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivationEnd, NavigationEnd, Router } from '@angular/router';
import * as Bowser from 'bowser';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ContactUsDialogComponent } from '@components/contact-us-dialog/contact-us-dialog.component';
import { Customer, PolicyService } from '@services/api/policy.service';
import { ContactFormFields, ContactService } from '@services/contact.service';
import { BannerNotification } from '@services/notifications.service';
import { UserService } from '@services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

// eslint-disable-next-line import/namespace
const browser = Bowser.getParser(window.navigator.userAgent);

interface MarketingLinks {
  home: string;
  factSheet: string;
  faq: string;
  contactUs: string;
  ourStory: string;
}

const customerMarketingLinks = {
  home: '/',
  factSheet: '/fact-sheet',
  faq: '/faq',
  contactUs: '/contact-us',
  ourStory: '/our-story'
} as MarketingLinks;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  @ViewChild('dropdownContainer')
  dropdownContainer: ElementRef;

  @ViewChild('profileButton')
  profileButton: ElementRef;

  @ViewChild('mobileMenuCheckbox')
  mobileMenuCheckbox: ElementRef;

  @Input()
  banner: BannerNotification;

  loginUrl = environment.isInMaintenanceMode ? environment.maintenanceUrl : environment.loginUrl;

  headerlessRoutes = [
    '/onboarding/login',
    '/onboarding/verify-identity',
    '/onboarding/register',
    '/contract',
    '/view-application'
  ];
  hidden = false;

  shortMenuRoutePrefixes = [
    '/dashboard',
    '/onboarding',
    '/contact-us',
    '/no-policy-found',
    '/session-expired'
  ];

  menulessRoutePrefixes = [
    '/onboarding/getting-started',
    '/onboarding/contract',
    '/onboarding/verify-identity',
    '/onboarding/register'
  ];

  showFullMenu = false;
  showHeader = false;
  showMenu = true;

  dropdownVisible = false;
  fullName = '';
  initials = '';
  email = '';

  customer: Customer;
  marketingLinks = {
    ...customerMarketingLinks
  };

  routerSubscription: Subscription;
  nameSubscription: Subscription;
  customerSubscription: Subscription;

  isSafari = browser.satisfies({
    mobile: {
      safari: '>=9',
    },
  });

  @HostBinding('class.hidden') get hiddenClass() { return this.hidden; }

  constructor(
    private router: Router,
    private auth: AuthService,
    private userService: UserService,
    private dialog: MatDialog,
    private contactService: ContactService,
    private policyService: PolicyService,
  ) { }

  ngOnInit(): void {
    this.routerSubscription = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd || e instanceof ActivationEnd),
    ).subscribe(this.handleNavigationEvent.bind(this));

    this.nameSubscription = this.userService.getFullName()
      .subscribe(fullName => {
        this.initials = this.getInitials(fullName);
        this.fullName = fullName.join(' ');
      });
  }

  // This would theoretically never live past the lifetime of the app itself,
  // but a best practice is to unsubscribe live subscriptions. (most observables besides http requests)
  ngOnDestroy() {
    this.routerSubscription?.unsubscribe();
    this.nameSubscription?.unsubscribe();
    this.customerSubscription?.unsubscribe();
  }

  handleNavigationEvent(routerEvent: NavigationEnd | ActivationEnd) {
    // The ActivationEnd happens first to reset hidden.
    // The NavigationEnd then checks if we are on a route we need to hide.
    if (routerEvent instanceof ActivationEnd) {
      this.hidden = routerEvent.snapshot.routeConfig.path === '**';
    } else if (routerEvent instanceof NavigationEnd) {
      this.hidden = false;
      this.headerlessRoutes.forEach(headerlessRoute => {
        if (routerEvent.url.indexOf(headerlessRoute) !== -1) {
          // we need the contract in the portal to be headerless but not the assembling screen
          if (
            headerlessRoute === '/contract' &&
            routerEvent.url.indexOf('/onboarding/contract') > -1
          ) {
            this.hidden = false;
          } else {
            this.hidden = true;
          }
        }
      });

      this.showFullMenu = true;
      this.shortMenuRoutePrefixes.forEach(shortMenuRoutePrefix => {
        if (routerEvent.url.indexOf(shortMenuRoutePrefix) !== -1) {
          if (shortMenuRoutePrefix === '/contact-us' || shortMenuRoutePrefix === '/no-policy-found') {
            if (this.isAuthenticated()) {
              this.showFullMenu = false;
            }
          }else{
            this.showFullMenu = false;
          }
        }
      });

      // decide to show customer or BGA links
      if (routerEvent.url.indexOf('/fast') === 0) {
        const newMarketingLinks: MarketingLinks = { ...customerMarketingLinks };
        Object.keys(customerMarketingLinks).forEach((marketingKey) => {
          newMarketingLinks[marketingKey] = `/fast/${customerMarketingLinks[marketingKey]}`;
        });

        this.marketingLinks = newMarketingLinks;
      } else {
        this.marketingLinks = { ...customerMarketingLinks };
      }

      this.showMenu = true;
      this.menulessRoutePrefixes.forEach(routePrefix => {
        if (routerEvent.url.indexOf(routePrefix) !== -1) {
          this.showMenu = false;
        }
      });

      this.customerSubscription?.unsubscribe();
      if (this.auth.isAuthenticated() && (this.showMenu && !this.showFullMenu)) {
        // Uses cached value to prevent fetching this from the API too much
        this.customerSubscription = this.policyService.getCustomer().subscribe((customer) => {
          this.customer = customer;
        });

        const decodedToken = this.auth.getDecodedToken();
        this.email = decodedToken.email;
      }

    }
  }

  isAuthenticated() {
    return this.auth.isAuthenticated();
  }

  public getOwnerEmail() {
    return this.auth.getDecodedToken()?.email || '';
  }

  getInitials(fullName: string[]) {
    return fullName.map(name => {
        return name?.charAt(0);
      })
      .join('');
  }

  @HostListener('document:click', ['$event'])
  clickout(event: { target: ElementRef<any>; }) {
    if (this.dropdownContainer?.nativeElement) {
      if (this.dropdownContainer.nativeElement.contains(event.target)) {
        this.dropdownVisible = !this.dropdownVisible;
      } else {
        this.dropdownVisible = false;
      }
      this.profileButton.nativeElement.setAttribute('aria-expanded', this.dropdownVisible);
    }
  }

  showContactModal() {
    const dialogRef = this.dialog.open(ContactUsDialogComponent, {
      data: {
        customer: this.customer,
        email: this.email,
      }
    });

    dialogRef.afterClosed().subscribe((formFields: ContactFormFields) => {
      if (formFields) {
        this.contactService.sendContactForm({
          ...formFields,
          subject: 'SwiftTerm Contact Form Submission: Authenticated User',
        });
      }
    });
  }

  hideMenu() {
    this.mobileMenuCheckbox.nativeElement.checked = false;
  }
}
