import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceGuard implements CanActivate {
  
  constructor() { }

  canActivate(): boolean {
    const isInMaintenanceMode = this.checkMaintenanceMode();
    console.log('Maintenance mode:', isInMaintenanceMode);
    if(isInMaintenanceMode) {
      window.location.assign(environment.maintenanceUrl)
      return false;
    }
    return true;
  }

  checkMaintenanceMode() {
    return environment.isInMaintenanceMode;
  }
  
}
