<div
  class="header-wrapper"
  *ngIf="!hidden"
>
  <header
    class="page-header"
    fxLayout="row"
    fxLayoutAlign="flex-start center"
  >
    <a [routerLink]="'/'" >
      <img
        src="assets/SymetraSwiftterm-logo.svg"
        alt="Symetra Logo"
      />
    </a>
    <div class="spacer"></div>
    <nav class="nav-links" *ngIf="showMenu">
      <a
        class="nav-link"
        [routerLink]="['/dashboard']"
        routerLinkActive="active"
        fxHide
        fxShow.gt-sm
        [routerLinkActiveOptions]="{ exact: true }"
        *ngIf="customer?.policies?.length > 1 && !showFullMenu && isAuthenticated"
      >
        My Policies
      </a>
      <a
        class="nav-link login"
        routerLinkActive="active"
        *ngIf="showFullMenu && !isAuthenticated()"
        [href]="loginUrl"
        [fxHide]="showFullMenu"
        [fxShow.gt-sm]="showFullMenu"
      >Log in</a>
      <a
        class="nav-link login"
        routerLinkActive="active"
        *ngIf="showFullMenu && isAuthenticated()"
        [routerLink]="['/dashboard']"
        [fxHide]="showFullMenu"
        [fxShow.gt-sm]="showFullMenu"
      >My account</a>
    </nav>
    <div *ngIf="showMenu && !showFullMenu">
      <div
        #dropdownContainer
        class="dropdown-container"
        *ngIf="isAuthenticated()"
      >
        <button
          #profileButton
          class="profile-button"
        >
          <span data-pii> {{initials}} </span>
        </button>
        <div *ngIf="dropdownVisible">
          <app-profile-dropdown
            [name]="fullName"
            [email]="getOwnerEmail()"
          >
          </app-profile-dropdown>
        </div>
      </div>
    </div>
  </header>
</div>
<app-banner *ngIf="banner" [config]="banner"></app-banner>


<input
  id="mobile-menu-checkbox"
  class="mobile-menu-checkbox sr-only"
  type="checkbox"
  #mobileMenuCheckbox
/>

<div class="mobile-menu-button">
  <label
    *ngIf="showFullMenu"
    for="mobile-menu-checkbox"
    [fxShow]="showFullMenu"
    [fxHide.gt-sm]="showFullMenu"
    >
    <img class="icon open-icon" src="assets/mobile-menu-open.svg" />
    <img class="icon close-icon" src="assets/mobile-menu-close.svg" />
  </label>

  <a
    class="nav-link login"
    routerLinkActive="active"
    *ngIf="showFullMenu && !isAuthenticated()"
    [href]="loginUrl"
    [fxHide.gt-sm]="true"
  >Log in</a>
  <a
    class="nav-link login"
    routerLinkActive="active"
    *ngIf="showFullMenu && isAuthenticated()"
    [routerLink]="['/dashboard']"
    [fxHide.gt-sm]="true"
  >My account</a>

</div>

<div [class.safari]="isSafari" class="mobile-menu-wrapper" id="mobile-menu">
  <a
    class="nav-link login"
    routerLinkActive="active"
    [routerLink]="['/dashboard']"
    (click)="hideMenu()"
  >
    <ng-container *ngIf="!isAuthenticated">Log in</ng-container>
    <ng-container *ngIf="isAuthenticated">My account</ng-container>
    <img src="assets/lock-green.svg" />
  </a>
</div>
