<app-header
  [banner]="banner"
  [class.has-banner]="!!banner"
></app-header>

<main
  [class.foot-padding]="isAuthenticated"
  [class.has-banner]="!!banner"
  [class.remove-min-height]="shouldRemoveMinHeight && isAuthenticated"
>

  <app-background-handler></app-background-handler>
  <router-outlet></router-outlet>
</main>
<!-- Temporarily hiding chatbot -->
<app-chat-bot *ngIf="false && isAuthenticated"></app-chat-bot>

<app-footer *ngIf="shouldShowFooter"></app-footer>

<app-spinner
  *ngIf="spinner$ | async"
  [fullScreen]=true
>
</app-spinner>
